import * as THREE from "three";
import { RenderPixelatedPass } from "three/examples/jsm/postprocessing/RenderPixelatedPass.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import image from "../../img/earth.webp";
import { getPixelProgress, getScrollProgress } from "./utils";
import throttle from "lodash.throttle";

document.addEventListener("DOMContentLoaded", () => {
    const baseYRotation = -1.5;
    const basePixelSize = 4;

    // Scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);
    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    );

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    // geometry
    const geometry = new THREE.SphereGeometry(1, 32, 32);

    // loading manager
    // const manager = new THREE.LoadingManager();
    // manager.onLoad = function () {
    //     document.querySelector(".loading").style.display = "none";

    // };

    // globe texture
    // const texture = new THREE.TextureLoader(manager).load(image);
    const texture = new THREE.TextureLoader().load(image);

    const material = new THREE.MeshBasicMaterial({
        map: texture,
    });

    // create 3d meshes & add to scene
    const globe = new THREE.Mesh(geometry, material);

    globe.rotation.y = baseYRotation;
    globe.rotation.x = 0.4;

    scene.add(globe);

    camera.position.z = 8;

    // pixelate
    composer = new EffectComposer(renderer);
    const renderPixelatedPass = new RenderPixelatedPass(
        basePixelSize,
        scene,
        camera,
        {
            depthEdgeStrength: 0.1, // 1 = bright edges
            depthEdgeThreshold: 0.1,
        }
    );

    composer.addPass(renderPixelatedPass);

    // Animate the globe
    let init = false;
    function animate() {
        requestAnimationFrame(animate);

        // set initial pixellation
        if (!init) {
            const pixelProgress = getPixelProgress();
            composer.setPixelRatio(pixelProgress / 10);
        }
        // RENDER
        // renderer.render(scene, camera); //nofx
        composer.render(); // fx
        init = true;
    }
    animate();

    // wait for the model to load before listening to RESIZE
    window.addEventListener("resize", function (e) {
        const isOnMobile = window.mobileCheck();
        if (!isOnMobile) {
            const pixelProgress = getPixelProgress();
            composer.setPixelRatio(pixelProgress / 10);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        }
    });

    // wait for the model to load before listening to SCROLL
    const onScroll = throttle(() => {
        const progress = getScrollProgress();

        // rotate globe slightly
        globe.rotation.y += progress / 10;

        // move camera
        camera.position.z = (2 - progress) * 4;

        // pixellate
        const pixelProgress = getPixelProgress(progress);
        composer.setPixelRatio(pixelProgress / 10); // 0.2 -> 0.7
    }, 50);
    document.addEventListener("scroll", () => {
        onScroll();
    });
});
