/**
 *
 * @returns number 0 -> 1
 */
export function getScrollProgress() {
    // calculate scroll progress
    const scroll = window.scrollY;
    const fullPageHeight = document.documentElement.scrollHeight;

    return scroll / fullPageHeight; // 0 -> 1
}

/**
 *
 * @param {number} progress 0 -> 1
 * @returns integer 2 -> 7
 */
export function getPixelProgress(progress = null) {
    if (progress === null) {
        progress = getScrollProgress();
    }
    // Get discreet values betweeb 2 and 7
    return Math.ceil((progress + 0.4) * 5); // 2 -> 7
}

export function debounce(method, delay) {
    clearTimeout(method._timeOutId);
    method._timeOutId = setTimeout(function () {
        method();
    }, delay);
}
